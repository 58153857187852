<template>
    <div>
        <section class="luanchpad_main bg p-4">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="luanchpad_head mb-5">
                            <h3>Launchpad</h3>
                        </div>
                    </div>

                    <!-- ________Ongoing Head________ -->
                    <div class="col-xl-12">
                        <div class="mb-5">
                            <div class="heading">
                                <p class="mb-2 fw-bold text-primary">Ongoing Project </p>
                            </div>
                            <!-- <OngoingComponent /> -->
                            <div class="row">
                                <div class="div col-xl-4 card border-0" v-for="(launchpad, index) in response.past" :key="index">
                                    <div class="ongoing_box row  align-items-center p-2">
                                        <div class="col-12 col-lg-4 text-center">
                                            <router-link :to="'/token?token='+ launchpad.symbol"
                                                    class="text-decoration-none">
                                                   
                                                    <div class="launch">
                                                      
                                                        <img :src="launchpad.image" alt="image" class="img-fluid"
                                                          >
                                                          <span class="badge bg-primary fw-bold mb-3">Live </span>
                                                      
                                                    </div>
                                                    
                                                </router-link>
                                        </div>
                                        <!-- ________Ongoing content________ -->
                                        <div class="col-12 col-lg-8">
                                                 <h5 class="card-title mb-1 d-flex justify-content-between">{{ launchpad.name }}      <span>{{ launchpad.symbol }}</span>
                                               </h5>
                                               <div class="d-flex justify-content-between fs-14">
                                                        <span class="text-primary">Expired At </span>
                                                        <span class="text-primary fw-bold">{{ launchpad.expired_at}} </span>
                                                    </div>
                                                   
                                                    <p class="card-text mb-0 fs-14">{{ (launchpad.disclaimer).slice(0, 100) }}
                                                    </p>

                                                  

                                                    <div class="card_footer d-flex justify-content-between my-3">
                                                        <router-link :to="'/token?token='+ launchpad.symbol"
                                                            class="dark-btn text-decoration-none rounded-pill text-center w-100">
                                                            Participate</router-link>
                                                    </div>
                                              
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-12">
                        <!-- ________Past Head________ -->
                        <div class="mb-5">
                            <div class="heading">
                                <p class="mb-4">Past</p>
                            </div>
                            <div class="row">
                                <div class="div col-xl-4 card border-0" v-for="(launchpad, index) in response.ongoing" :key="index">
                                    <div class="ongoing_box row  align-items-center p-2">
                                        <!-- ________Past content________ -->
                                        <div class="col-12 col-lg-4 text-center">
                                            <router-link :to="'/token?token='+ launchpad.symbol"
                                                    class="text-decoration-none">
                                                   
                                                    <div class="launch">
                                                      
                                                        <img :src="launchpad.image" alt="image" class="img-fluid"
                                                          >
                                                          <span class="badge bg-primary fw-bold mb-3">Past </span>
                                                      
                                                    </div>
                                                    
                                                </router-link>
                                        </div>
                                        <!-- ________Ongoing content________ -->
                                        <div class="col-12 col-lg-8">
                                                 <h5 class="card-title mb-1 d-flex justify-content-between">{{ launchpad.name }}      <span>{{ launchpad.symbol }}</span>
                                               </h5>
                                               <div class="d-flex justify-content-between fs-14">
                                                        <span class="text-warning">Expired At </span>
                                                        <span class="text-warning fw-bold">{{ launchpad.expired_at}} </span>
                                                    </div>
                                                   
                                                    <p class="card-text mb-0 fs-14">{{ (launchpad.disclaimer).slice(0, 100) }}
                                                    </p>

                                                  

                                                   
                                              
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-12">
                        <!-- ________Upcoming Head________ -->
                        <div class="mb-5">
                            <div class="heading">
                                <p class="mb-4">Upcoming</p>
                            </div>
                            <div class="row">
                                <div class="div col-xl-4" v-for="(multiple, index) in response.upcoming" :key="index">
                                    <div class="ongoing_box">
                                        <!-- ________Upcoming content________ -->
                                        <div class="ongoing_content">
                                            <div class="card" >
                                                <router-link :to="'/token?token='+ multiple.symbol"
                                                    class="text-decoration-none">
                                                    <div class="launch">
                                                        <img :src="multiple.image" alt="image" class="img-fluid">
                                                    </div>

                                                    <div class="card-body">
                                                        <h5 class="card-title mb-1">{{ multiple.name }}</h5>
                                                        <span>{{ multiple.symbol }}</span>


                                                        <div class="d-flex justify-content-between py-3 border_bottom">
                                                            <span class="dark-btn">Upcoming </span>
                                                            <span class="dark-btn">{{ multiple.started_at}} </span>
                                                        </div>
                                                    </div>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
import { } from 'dom7';
import Apiclass from '@/api/api';
// import OngoingComponent from '@/components/Launchpadnew/OngoingComponent.vue'
export default {
    name: 'LaunchpadNewView',
    // components: {
    //     OngoingComponent
    // }
    data() {
        return {
            response: {}
        }
    },
    created() {
        this.tokenGet();
    },
    methods: {
        async tokenGet() {
            let res = await Apiclass.getNodeRequest('launchpad/user/token_get')
            this.response = res.data.data;
            console.log({ res: this.response });
        }
    }
}
</script>

<style scoped>
.card-text {
    color: var(--grey-text);
    font-size: 15px;
}

.card-body span {
    font-size: 13px;
}

.card-body h5 {
    font-size: 16px;
    font-weight: 500;
}

.dark-btn {
    background: var(--primary);
    padding: 6px 12px;
    border: 1px solid var(--primary);
    font-size: 15px;
    font-weight: 500;
    font-family: "Poppins";
    border-radius: 3px;
    color: var(--white) !important;
}

.card {
    /* box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.3); */
    box-shadow: 10px 10px 42px rgba(0, 0, 0, 0.2);
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    border-left: 1px solid rgba(255, 255, 255, 0.5);
    overflow: hidden;
    border-radius: 15px;
}

.border_bottom {
    border-bottom: 1px solid #ccc;
}

.luanchpad_main {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 800px;
}
.launch img{
    width: 100%;
    object-fit: cover;

}


.heading p {
    color: var(--text);
}
</style>
